<template>
  <div>
    <section class="main-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <!-- leaddetails-left -->
            <div class="leaddetails-left deals-bg">
              <div class="deal-left">
                <!-- profiles -->
                <div class="details-profile">
                  <a href="#"
                    ><img
                      class="img-fluid"
                      src="../../assets/img/Ellipse 48.svg"
                      alt=""
                  /></a>
                  <h3>{{ customer.first_name }} {{ customer.last_name }}</h3>
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Email:</td>
                        <td>{{ customer.email }}</td>
                      </tr>
                      <tr>
                        <td>Phone:</td>
                        <td>{{ customer.phone }}</td>
                      </tr>
                      <!-- <tr>
                        <td>Source:</td>
                        <td>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>cars.com</option>
                            <option>lotus.com</option>
                            <option>martin.com</option>
                          </select>
                        </td>
                      </tr> -->
                      <tr>
                        <td>ID:</td>
                        <td>#{{ this.$route.params.id }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="user-contacts">
                    <ul>
                      <li>
                        <a href="#"
                          ><img
                            class="img-fluid"
                            src="../../assets/img/Phone (1).svg"
                            alt=""
                          />
                          <p>Call</p>
                        </a>
                      </li>
                      <li>
                        <a href="#"
                          ><img
                            class="img-fluid"
                            src="../../assets/img/Phone (2).svg"
                            alt=""
                          />
                          <p>Text</p>
                        </a>
                      </li>
                      <li>
                        <a href="#"
                          ><img
                            class="img-fluid"
                            src="../../assets/img/Group 198.svg"
                            alt=""
                          />
                          <p>Email</p>
                        </a>
                      </li>

                      <li>
                        <a href="#"
                          ><img
                            class="img-fluid"
                            src="../../assets/img/mess.svg"
                            alt=""
                          />
                          <p>FB</p>
                        </a>
                      </li>

                      <li>
                        <a href="#"
                          ><img
                            class="img-fluid"
                            src="../../assets/img/coun.svg"
                            alt=""
                          />
                          <p>Chat</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Agent:</td>
                        <td>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option>Abcstion</option>
                            <option>Abcstion</option>
                            <option>martin.com</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- profiles -->
              </div>

              <!-- Leadsheets-table -->
              <div class="Leadsheets-table">
                <div class="deal-title">
                  <h3>ROs</h3>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Date modified</th>
                      <th>Ros#</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="element in roList" :key="element">
                      <td>{{ element.a_date }}</td>
                      <td>
                        <a
                          @click="getRoDetail(element.id)"
                          href="javascript:void(0)"
                          >{{ element.ro_no }}</a
                        >
                      </td>
                      <td>
                        <a href="#">{{ element.status }}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Leadsheets-table -->
              <!-- Leadsheets-table -->
              <br />
              <div class="Leadsheets-table">
                <div class="deal-title">
                  <h3>In Equity</h3>
                  <div class="yes-no">
                    <img
                      class="img-fluid"
                      src="../../assets/img/Yes.png"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <!-- Leadsheets-table -->
            </div>
            <!-- leaddetails-left -->

            <!-- leaddetails-mid -->
            <div class="leaddetails-mid">
              <!-- Intersets-deal -->
              <div class="note-area">
                <div class="accordion" id="accordionExample51">
                  <div class="card">
                    <div class="card-header" id="headingOne51">
                      <div class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne51"
                          aria-expanded="true"
                          aria-controls="collapseOne51"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />
                              Current Vehicle
                            </h3>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div
                      id="collapseOne51"
                      class="collapse show"
                      aria-labelledby="headingOne51"
                      data-parent="#accordionExample51"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="current-info">
                              <div class="media">
                                <img
                                  src="../../assets/img/customers.png"
                                  class="mr-3 img-fluid"
                                  alt=""
                                />
                                <div class="button-wrapper">
                                  <span class="label">
                                    <img
                                      class="img-fluid"
                                      src="../../assets/img/camera.svg"
                                      alt=""
                                    /> </span
                                  ><input
                                    type="file"
                                    name="upload"
                                    id="upload"
                                    class="upload-box"
                                    placeholder="Upload File"
                                  />
                                </div>
                                <div class="media-body">
                                  <h5 class="mt-0">
                                    {{ customer.v_make }}
                                    {{ customer.v_model }} {{ customer.v_year }}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="complate text-right">
                              <ul>
                                <li class="navbar dropdown">
                                  <a
                                    class="dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    >Dropdown
                                  </a>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="navbarDropdown"
                                  >
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Action</a>
                                  </div>
                                </li>
                                <li><a href="#">Inspection</a></li>
                              </ul>
                            </div>
                          </div>

                          <div class="col-12">
                            <hr />
                          </div>

                          <div class="col-md-6">
                            <div class="current-info">
                              <p>
                                Licence Plate # :
                                <span>{{ customer.licenceplate }}</span>
                              </p>
                              <p>VIN : <span>AW190376</span></p>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="current-info">
                              <p>
                                Hangtag : <span>{{ customer.hangtag }}</span>
                              </p>
                              <p>RO Number : <span>AW190376</span></p>
                              <p>Last Serviced : <span>03/22/2021</span></p>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="current-info">
                              <p>
                                Ownership Type :
                                {{ customer.ownershiptype }}
                              </p>
                              <p>
                                Lease End Date :
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="Pick your date"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Intersets-deal -->

              <!-- Intersets-deal -->
              <div class="Intersets-deal">
                <div class="infotabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="Info-tab"
                        data-toggle="tab"
                        href="#Info"
                        role="tab"
                        aria-controls="Info"
                        aria-selected="true"
                        >Appointment Info</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="Around-tab"
                        data-toggle="tab"
                        href="#Around"
                        role="tab"
                        aria-controls="Around"
                        aria-selected="false"
                        >Walk Around</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="Quote-tab"
                        data-toggle="tab"
                        href="#Quote"
                        role="tab"
                        aria-controls="Quote"
                        aria-selected="false"
                        >Quote</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="Payment-tab"
                        data-toggle="tab"
                        href="#Payment"
                        role="tab"
                        aria-controls="Payment"
                        aria-selected="false"
                        >Payment</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="Info"
                      role="tabpanel"
                      aria-labelledby="Info-tab"
                    >
                      <!-- infotabs -->
                      <div class="info-tabs">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="current-info">
                              <p>
                                Date :
                                <span>{{
                                  customer.date | deskingdateformate
                                }}</span>
                              </p>
                              <p>
                                Time :
                                <span
                                  >{{ customer.from_time }} -{{
                                    customer.to_time
                                  }}</span
                                >
                              </p>
                              <p>Advisor : <span>Henry Poloski</span></p>
                              <p>Comp Time : <span>11:00 PM 4/21/20</span></p>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="current-info">
                              <p>
                                Transportation :
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Leased</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                              </p>
                              <p>
                                Exp Comp Time :
                                <input
                                  type="datetime-local"
                                  class="form-control"
                                  placeholder="Pick your date"
                                />
                              </p>
                            </div>
                          </div>
                        </div>

                        <!-- servicesinfo-box -->
                        <div class="servicesinfo-box">
                          <div class="sortable-items">
                            <form role="form" action="" method="POST">
                              <div
                                class="multi-field-wrapper2 table-responsive-md"
                              >
                                <table class="table desksettig">
                                  <thead>
                                    <tr>
                                      <th>Services</th>
                                      <th></th>
                                      <th>DMS Opcode</th>
                                      <th class="text-right">Price $</th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody
                                    class="multi-fields2 sortable ui-sortable"
                                  >
                                    <tr
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                      v-for="(element, counter) in ro"
                                      v-bind:key="counter"
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="../../assets/img/dots.svg"
                                              alt=""
                                          /></span>
                                          <select
                                            v-model="element.service"
                                            class="form-control"
                                          >
                                            <option>Selete name</option>
                                            <option value="Change Tires">
                                              Change Tires
                                            </option>
                                            <option
                                              value="Replace Engine Oil &amp; Oil
                                              Filter"
                                            >
                                              Replace Engine Oil &amp; Oil
                                              Filter
                                            </option>
                                            <option value="Replace Engine">
                                              Replace Engine
                                            </option>
                                            <option value="Change Tires">
                                              Change Tires
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <a href="#"
                                          ><img
                                            class="img-fluid ddg"
                                            src="../../assets/img/edit-button.svg"
                                            alt=""
                                        /></a>
                                      </td>
                                      <td>
                                        <p>Rotate</p>
                                      </td>
                                      <td class="text-right">
                                        <p>
                                          <input
                                            type="text"
                                            class="form-control"
                                            required
                                            v-model="element.price"
                                          />
                                        </p>
                                      </td>
                                      <td>
                                        <img
                                          @click="deleteService"
                                          class="remove-field2"
                                          src="../../assets/img/colse.png"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <a @click="addService" class="add-field2">
                                  Add Item
                                </a>
                                <div class="totals">
                                  <ul>
                                    <li>Total</li>
                                    <li>{{ calculateRo }}</li>
                                  </ul>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <!-- servicesinfo-box -->
                      </div>
                      <!-- infotabs -->
                    </div>

                    <div
                      class="tab-pane fade"
                      id="Around"
                      role="tabpanel"
                      aria-labelledby="Around-tab"
                    >
                      <!-- current-info -->
                      <div class="current-info info2">
                        <form>
                          <div class="form-row">
                            <div class="col-md-6 col-lg-4">
                              <p>Appt Type : <span>Scheduled</span></p>
                            </div>
                            <div class="col-md-6 col-lg-4">
                              <p>
                                Check-In Time : <span>10:00AM 4/21/21</span>
                              </p>
                            </div>
                            <div class="col-md-6 col-lg-4">
                              <p>Last Mileage : <span>36,200</span></p>
                            </div>
                            <div class="col-md-6 col-lg-3">
                              <label for="inputEmail4">Advisor :</label>
                              <select class="form-control">
                                <option>Henry Poloski</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                            <div class="col-md-6 col-lg-3">
                              <label for="inputEmail4">C. Mileage :</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="40000"
                              />
                            </div>
                            <div class="col-md-6 col-lg-3">
                              <label for="inputEmail4">Hangtag :</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="customer.hangtag"
                              />
                            </div>
                            <div class="col-md-6 col-lg-3">
                              <label for="inputEmail4">Transportation :</label>
                              <select class="form-control">
                                <option>Dropoff</option>
                                <option>Henry Poloski</option>
                              </select>
                            </div>
                            <div class="col-12">
                              <a class="editvshicle" href="#"
                                >Edit Vehicle Information</a
                              >
                            </div>
                          </div>
                        </form>

                        <!-- cr -->
                        <div class="cr">
                          <p>
                            CR :
                            <span
                              ><img
                                class="img-fluid"
                                src="../../assets/img/dent.svg"
                                alt=""
                              />
                              Dent 1</span
                            >
                            <span
                              ><img
                                class="img-fluid"
                                src="../../assets/img/srs.svg"
                                alt=""
                              />
                              Chip 1</span
                            >
                            <span
                              ><img
                                class="img-fluid"
                                src="../../assets/img/chip.svg"
                                alt=""
                              />
                              Scratch 1</span
                            >
                          </p>
                        </div>
                        <!-- cr -->

                        <!-- cartabs -->
                        <div class="cartabs">
                          <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <a
                                class="nav-link active"
                                id="Car-tab"
                                data-toggle="tab"
                                href="#Car"
                                role="tab"
                                aria-controls="Car"
                                aria-selected="true"
                                >Car</a
                              >
                            </li>
                            <li class="nav-item" role="presentation">
                              <a
                                class="nav-link"
                                id="Pickup-tab"
                                data-toggle="tab"
                                href="#Pickup"
                                role="tab"
                                aria-controls="Pickup"
                                aria-selected="false"
                                >Pickup</a
                              >
                            </li>
                            <li class="nav-item" role="presentation">
                              <a
                                class="nav-link"
                                id="Suv-tab"
                                data-toggle="tab"
                                href="#Suv"
                                role="tab"
                                aria-controls="Suv"
                                aria-selected="false"
                                >Suv</a
                              >
                            </li>
                          </ul>
                          <div class="tab-content" id="myTabContent">
                            <div
                              class="tab-pane fade show active"
                              id="Car"
                              role="tabpanel"
                              aria-labelledby="Car-tab"
                            >
                              <!-- cartabs-image -->
                              <div class="cartabs-image">
                                <img
                                  v-if="canvasimages.sketchimageforcar"
                                  class="img-fluid"
                                  :src="canvasimages.sketchimageforcar"
                                  @click="
                                    openImageEditor(
                                      canvasimages.sketchimageforcar,
                                      1
                                    )
                                  "
                                  alt=""
                                />
                                <img
                                  v-else
                                  class="img-fluid"
                                  src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/c6675434172083a152e52e88a6a9a2b5.jpg"
                                  @click="
                                    openImageEditor(
                                      'https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/c6675434172083a152e52e88a6a9a2b5.jpg',
                                      1
                                    )
                                  "
                                  alt=""
                                />
                              </div>
                              <!-- cartabs-image -->
                            </div>
                            <div
                              class="tab-pane fade"
                              id="Pickup"
                              role="tabpanel"
                              aria-labelledby="Pickup-tab"
                            >
                              <!-- cartabs-image -->
                              <div class="cartabs-image">
                                <!-- <img
                                  class="img-fluid"
                                  src="../../assets/img/car2.png"
                                  alt=""
                                /> -->

                                <img
                                  v-if="canvasimages.sketchimageforpickup"
                                  class="img-fluid"
                                  :src="canvasimages.sketchimageforpickup"
                                  @click="openImageEditor('car2.png', 2)"
                                  alt=""
                                />
                                <img
                                  v-else
                                  class="img-fluid"
                                  src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/783f6f879d0b3b21f7b58eb5f82103b4.jpg"
                                  @click="
                                    openImageEditor(
                                      'https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/783f6f879d0b3b21f7b58eb5f82103b4.jpg',
                                      2
                                    )
                                  "
                                  alt=""
                                />
                              </div>
                              <!-- cartabs-image -->
                            </div>
                            <div
                              class="tab-pane fade"
                              id="Suv"
                              role="tabpanel"
                              aria-labelledby="Suv-tab"
                            >
                              <!-- cartabs-image -->
                              <div class="cartabs-image">
                                <img
                                  v-if="canvasimages.sketchimageforsuv"
                                  class="img-fluid"
                                  :src="canvasimages.sketchimageforsuv"
                                  @click="
                                    openImageEditor(
                                      'https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/bc041f744850be491e8954fa0cd47dc2.jpg',
                                      3
                                    )
                                  "
                                  alt=""
                                />
                                <img
                                  v-else
                                  class="img-fluid"
                                  src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/bc041f744850be491e8954fa0cd47dc2.jpg"
                                  @click="
                                    openImageEditor(
                                      'https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/bc041f744850be491e8954fa0cd47dc2.jpg',
                                      3
                                    )
                                  "
                                  alt=""
                                />
                              </div>
                              <!-- cartabs-image -->
                            </div>
                          </div>
                        </div>
                        <!-- cartabs -->
                      </div>
                      <!-- current-info -->

                      <!-- note-area -->
                      <div class="note-area">
                        <div class="accordion1" id="accordionExample51">
                          <div class="card">
                            <div class="card-header" id="headingOne51">
                              <div class="mb-0">
                                <button
                                  class="btn btn-link"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne51"
                                  aria-expanded="true"
                                  aria-controls="collapseOne51"
                                >
                                  <div class="detais-title">
                                    <h3>
                                      <img
                                        class="img-fluid arrow-down"
                                        src="../../assets/img/arrow-down.svg"
                                        alt=""
                                      />
                                      Photos
                                    </h3>
                                  </div>
                                </button>
                                <form @submit.prevent="imageUpload">
                                  <div class="button-wrapper">
                                    <span class="label">
                                      <img
                                        class="img-fluid"
                                        src="../../assets/img/carema2.svg"
                                        alt=""
                                      />
                                    </span>

                                    <input
                                      type="file"
                                      name="upload"
                                      id="upload"
                                      v-on:change="onImageChange"
                                      multiple
                                      class="upload-box"
                                      placeholder="Upload File"
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                            <br /><br /><br />
                            <div
                              id="collapseOne51"
                              class="collapse show"
                              aria-labelledby="headingOne51"
                              data-parent="#accordionExample51"
                            >
                              <div class="card-body">
                                <div class="uploadcar-img">
                                  <ul>
                                    <li
                                      v-for="(image, index) in carimage"
                                      :key="index"
                                    >
                                      <a href="#"
                                        ><img
                                          style="width: 100px; height: 100px"
                                          class="img-fluid"
                                          :src="image.name"
                                          alt=""
                                      /></a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- note-area -->

                      <!-- note-area -->
                      <div class="note-area">
                        <div class="accordion" id="accordionExample5">
                          <div class="card">
                            <div class="card-header" id="headingOne5">
                              <div class="mb-0">
                                <button
                                  class="btn btn-link"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne5"
                                  aria-expanded="true"
                                  aria-controls="collapseOne5"
                                >
                                  <div class="detais-title">
                                    <h3>
                                      <img
                                        class="img-fluid arrow-down"
                                        src="../../assets/img/arrow-down.svg"
                                        alt=""
                                      />
                                      Notes
                                    </h3>
                                  </div>
                                </button>
                              </div>
                            </div>

                            <div
                              id="collapseOne5"
                              class="collapse show"
                              aria-labelledby="headingOne5"
                              data-parent="#accordionExample5"
                            >
                              <div class="card-body">
                                <form action="">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Start typing to add a note..."
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- note-area -->
                    </div>

                    <div
                      class="tab-pane fade"
                      id="Quote"
                      role="tabpanel"
                      aria-labelledby="Quote-tab"
                    >
                      <!-- servicesinfo-box -->
                      <div class="servicesinfo-box">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="pre-name">
                              <h2>
                                {{ customer.first_name }}
                                {{ customer.last_name }}
                              </h2>
                              <p>{{ customer.email }}</p>
                              <p>{{ customer.phone }}</p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="pre-date">
                              <h5>Date : {{ customer.date }}</h5>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-6 col-lg-4">
                            <div class="phn-person">
                              <h4>
                                {{ customer.v_year }} {{ customer.v_make }}
                                <br />{{ customer.v_model }}
                              </h4>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-4">
                            <div class="phn-person">
                              <p>
                                Licence Plate # :
                                <span>{{ customer.licenceplate }}</span>
                              </p>
                              <p>VIN : <span>AW190376</span></p>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-4">
                            <div class="phn-person">
                              <p>Keytag : <span>4762</span></p>
                              <p>RO # : <span>AW190376</span></p>
                            </div>
                          </div>
                        </div>

                        <div class="sortable-items">
                          <form role="form" action="" method="POST">
                            <div
                              class="multi-field-wrapper2 table-responsive-md"
                            >
                              <table class="table desksettig">
                                <thead>
                                  <tr>
                                    <th>Services</th>
                                    <th></th>
                                    <th>DMS Opcode</th>
                                    <th class="text-right">Price $</th>
                                    <th></th>
                                  </tr>
                                </thead>

                                <tbody
                                  class="multi-fields2 sortable ui-sortable"
                                >
                                  <tr
                                    class="
                                      ui-state-default
                                      multi-field2
                                      ui-sortable-handle
                                    "
                                    v-for="service in ro"
                                    :key="service"
                                  >
                                    <td>
                                      <div class="names">
                                        <span class="ddd"></span>
                                        <p>{{ service.service }}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <a href="#"
                                        ><img
                                          class="img-fluid ddg"
                                          src="../../assets/img/edit-button.svg"
                                          alt=""
                                      /></a>
                                    </td>
                                    <td>
                                      <p>Rotate</p>
                                    </td>
                                    <td class="text-right">
                                      <p>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="service.price"
                                        />
                                      </p>
                                    </td>
                                    <td>
                                      <img
                                        class="remove-field2"
                                        src="../../assets/img/colse.png"
                                        alt=""
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!-- 
                              <button type="button" class="add-field2">
                                Add Item
                              </button> -->
                              <div class="totals">
                                <ul>
                                  <li>Total</li>
                                  <li>{{ calculateRo }}</li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <!-- servicesinfo-box -->

                      <div class="share-print">
                        <ul>
                          <li>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#exampleModal201"
                              ><img
                                class="img-fluid"
                                src="../../assets/img/share 1.svg"
                                alt=""
                              />
                              Share
                            </a>
                          </li>

                          <li>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#exampleModal202"
                              ><img
                                class="img-fluid"
                                src="../../assets/img/printer.svg"
                                alt=""
                              />
                              Print</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="Payment"
                      role="tabpanel"
                      aria-labelledby="Payment-tab"
                    >
                      <!-- Payment -->
                      <div class="payments">
                        <form>
                          <div class="form-group row">
                            <label
                              for="staticEmail"
                              class="col-sm-4 col-form-label"
                              >Choose A Method :</label
                            >
                            <div class="col-sm-8">
                              <div class="desk-radio">
                                <div class="form-group">
                                  <label class="containerw">
                                    Card
                                    <input
                                      type="radio"
                                      value="Finance"
                                      name="radio"
                                      @change="onChangeHandeler('card')"
                                      checked=""
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  <label class="containerw">
                                    Check<input
                                      type="radio"
                                      value="Lease"
                                      name="radio"
                                      @change="onChangeHandeler('check')"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  <label class="containerw">
                                    Cash<input
                                      type="radio"
                                      value="Lease"
                                      @change="onChangeHandeler('cash')"
                                      name="radio"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                  <label class="containerw">
                                    Other<input
                                      type="radio"
                                      @onChange="onChangeHandeler('other')"
                                      value="Lease"
                                      name="radio"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <!-- Payment -->

                      <!-- payment-getway -->
                      <div class="payment-getway" id="card">
                        <p>Choose A Card :</p>
                        <ul>
                          <li>
                            <a class="active" href="#"
                              ><img
                                class="img-fluid"
                                src="../../assets/img/visa.png"
                                alt=""
                            /></a>
                          </li>
                          <li>
                            <a href="#"
                              ><img
                                class="img-fluid"
                                src="../../assets/img/master.png"
                                alt=""
                            /></a>
                          </li>
                          <li>
                            <a href="#"
                              ><img
                                class="img-fluid"
                                src="../../assets/img/visa.png"
                                alt=""
                            /></a>
                          </li>
                        </ul>
                        <div id="card-element"></div>

                        <!-- <button
                          class="
                            flex
                            mx-auto
                            text-white
                            bg-indigo-500
                            border-0
                            py-2
                            px-8
                            focus:outline-none
                            hover:bg-indigo-600
                            rounded
                            text-lg
                          "
                          @click="processPayment"
                          :disabled="paymentProcessing"
                          v-text="paymentProcessing ? 'Processing' : 'Pay Now'"
                        ></button> -->
                        <!-- <form>
                          <div class="form-row">
                            <div class="col-12">
                              <div class="form-group">
                                <label>Card Holder Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Name on card"
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group">
                                <label>Card Number</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="***********"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Exp Date</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>CVC</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                          <button type="submit" class="submit-btn">
                            Charge
                          </button>
                        </form> -->
                      </div>
                      <div
                        class="payment-getway"
                        style="display: none"
                        id="check"
                      >
                        <label>Check #</label>
                        <input type="text" class="form-control" />
                      </div>
                      <div
                        class="payment-getway"
                        style="display: none"
                        id="cash"
                      ></div>
                      <div
                        class="payment-getway"
                        style="display: none"
                        id="other"
                      ></div>
                      <!-- payment-getway -->

                      <div class="share-print text-center">
                        <ul>
                          <li>
                            <a
                              @click="processPayment"
                              :disabled="paymentProcessing"
                              v-text="
                                paymentProcessing ? 'Processing' : 'Pay Now'
                              "
                              ><img
                                class="img-fluid"
                                src="../../assets/img/share 1.svg"
                                alt=""
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              data-toggle="modal"
                              data-target="#exampleModal201"
                              ><img
                                class="img-fluid"
                                src="../../assets/img/share 1.svg"
                                alt=""
                              />
                              Share
                            </a>
                          </li>
                          <li>
                            <a
                              ><img
                                class="img-fluid"
                                src="../../assets/img/printer.svg"
                                alt=""
                              />
                              Print</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Intersets-deal -->
            </div>
            <!-- leaddetails-mid -->

            <!-- leaddetails-right -->
            <div class="leaddetails-right">
              <!-- appointments -->
              <div class="appointments">
                <div class="accordion" id="accordionExample3">
                  <div class="card">
                    <div class="card-header" id="headingOne3">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne3"
                          aria-expanded="true"
                          aria-controls="collapseOne3"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Appointments
                              <a
                                class="add-appoi add-appi"
                                v-on:click.stop.prevent="
                                  toggleAddAppointment('show')
                                "
                                ><img
                                  class="img-fluid"
                                  src="../../assets/img/24. Close.svg"
                                  alt=""
                                />
                                add</a
                              >
                            </h3>
                          </div>
                        </button>
                        <p>
                          <img
                            class="img-fluid calenders"
                            src="../../assets/img/Vector (4).svg"
                            alt=""
                          />
                        </p>
                      </h2>
                    </div>

                    <div
                      id="collapseOne3"
                      class="collapse show"
                      aria-labelledby="headingOne3"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        <div
                          class="calendar calendar-first"
                          id="calendar_first"
                        >
                          <div class="calendar_header">
                            <h2></h2>
                            <button class="switch-month switch-right">
                              <img
                                class="img-fluid"
                                src="../../assets/img/Calendarright.svg"
                                alt=""
                              />
                            </button>
                            <button class="switch-month switch-left">
                              <img
                                class="img-fluid"
                                src="../../assets/img/Calendarleft.svg"
                                alt=""
                              />
                            </button>
                          </div>
                          <div class="calendar_weekdays"></div>
                          <div class="calendar_content"></div>
                        </div>
                        <!-- <div class="active-meeting simplebar" id="myElement2">
                        
                          <div class="media">
                            <p class="mr-3">14:00</p>
                            <div class="media-body green-meeting">
                              <h5 class="mt-0">Meeting Call with ninja</h5>
                              <p>10:05 AM - 11:30 AM</p>
                              <div class="appo-menu">
                                <ul>
                                  <li>
                                    <a class="okay" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#exampleModal20"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a class="notok" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z"
                                          fill="#3151A1"
                                        /></svg
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                         
                          <div class="media">
                            <p class="mr-3">14:00</p>
                            <div class="media-body blue-meeting">
                              <h5 class="mt-0">Meeting Call with ninja</h5>
                              <p>10:05 AM - 11:30 AM</p>
                              <div class="appo-menu">
                                <ul>
                                  <li>
                                    <a class="okay" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#exampleModal20"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a class="notok" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z"
                                          fill="#3151A1"
                                        /></svg
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          
                          <div class="media">
                            <p class="mr-3">14:00</p>
                            <div class="media-body red-meeting">
                              <h5 class="mt-0">Meeting Call with ninja</h5>
                              <p>10:05 AM - 11:30 AM</p>
                              <div class="appo-menu">
                                <ul>
                                  <li>
                                    <a class="okay" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#exampleModal20"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a class="notok" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z"
                                          fill="#3151A1"
                                        /></svg
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        
                          <div class="media">
                            <p class="mr-3">14:00</p>
                            <div class="media-body red-meeting">
                              <h5 class="mt-0">Meeting Call with ninja</h5>
                              <p>10:05 AM - 11:30 AM</p>
                              <div class="appo-menu">
                                <ul>
                                  <li>
                                    <a class="okay" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#exampleModal20"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0)">
                                          <path
                                            d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                                            fill="#3151A1"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="10"
                                              height="10"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs></svg
                                    ></a>
                                  </li>
                                  <li>
                                    <a class="notok" href="#"
                                      ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z"
                                          fill="#3151A1"
                                        /></svg
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tasks-area -->
              <div class="tasks-area">
                <div class="accordion" id="accordionExample4">
                  <div class="card">
                    <div class="card-header" id="headingOne4">
                      <div class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne4"
                          aria-expanded="true"
                          aria-controls="collapseOne4"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />
                              Tasks
                              <a
                                class="add-appoi addtask"
                                v-on:click.stop.prevent="toggleAddTask('show')"
                                ><img
                                  class="img-fluid"
                                  src="../../assets/img/24. Close.svg"
                                  alt=""
                                />
                                Add Task</a
                              >
                            </h3>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div
                      id="collapseOne4"
                      class="collapse show"
                      aria-labelledby="headingOne4"
                      data-parent="#accordionExample4"
                    >
                      <div class="card-body">
                        <div class="all-task simplebar" id="myElement3">
                          <!-- active-Tasks -->
                          <div class="media">
                            <p class="mr-3">
                              <label class="check2">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </p>
                            <div class="media-body">
                              <h5 class="mt-0">
                                Sales-24 hr Follow up call and/or email
                              </h5>
                              <p>
                                Scheduled for 20 Apr
                                <a href="#">Assigned to Jamil</a>
                              </p>
                            </div>
                          </div>
                          <!-- active-Tasks -->

                          <!-- active-Tasks -->
                          <div class="media">
                            <p class="mr-3">
                              <label class="check2">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </p>
                            <div class="media-body">
                              <h5 class="mt-0">
                                Sales-24 hr Follow up call and/or email
                              </h5>
                              <p>
                                Scheduled for 20 Apr
                                <a href="#">Assigned to Jamil</a>
                              </p>
                            </div>
                          </div>
                          <!-- active-Tasks -->

                          <!-- active-Tasks -->
                          <div class="media">
                            <p class="mr-3">
                              <label class="check2">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </p>
                            <div class="media-body">
                              <h5 class="mt-0">
                                Sales-24 hr Follow up call and/or email
                              </h5>
                              <p>
                                Scheduled for 20 Apr
                                <a href="#">Assigned to Jamil</a>
                              </p>
                            </div>
                          </div>
                          <!-- active-Tasks -->
                          <!-- active-Tasks -->
                          <div class="media">
                            <p class="mr-3">
                              <label class="check2">
                                <input type="checkbox" />
                                <span class="checkmarks"></span>
                              </label>
                            </p>
                            <div class="media-body">
                              <h5 class="mt-0">
                                Sales-24 hr Follow up call and/or email
                              </h5>
                              <p>
                                Scheduled for 20 Apr
                                <a href="#">Assigned to Jamil</a>
                              </p>
                            </div>
                          </div>
                          <!-- active-Tasks -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tasks-area -->

              <!-- Customer's Rating-area -->
              <div class="tasks-area">
                <div class="accordion" id="accordionExample04">
                  <div class="card">
                    <div class="card-header" id="headingOne04">
                      <div class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne04"
                          aria-expanded="true"
                          aria-controls="collapseOne04"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />
                              Customer's Rating
                            </h3>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div
                      id="collapseOne04"
                      class="collapse show"
                      aria-labelledby="headingOne04"
                      data-parent="#accordionExample04"
                    >
                      <div class="card-body">
                        <Progress
                          style="margin-left: 82px"
                          :transitionDuration="1000"
                          strokeColor="#19CD9D"
                          :radius="50"
                          :strokeWidth="10"
                          value="90"
                        >
                        </Progress>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Customer's Rating-area -->
            </div>
            <!-- leaddetails-right -->
          </div>
        </div>
      </div>
    </section>

    <!-- add-appointments -->
    <div class="add-appointments add-appointment">
      <div class="modal-header">
        <h5 class="modal-title">Add Appointment</h5>
        <a
          class="close hide-appi"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="toggleAddAppointment('hide')"
        >
          <span aria-hidden="true"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Closex.svg"
              alt=""
          /></span>
        </a>
      </div>
      <form v-on:submit.prevent="addNewAppointment">
        <div class="add-body">
          <div class="form-group">
            <label for="inputAddress">Date</label>
            <date-range-picker
              :min-date="yesterdayDate"
              :locale-data="{ format: 'mmmm dd, yyyy' }"
              v-model="addApt.ufDate"
              :single-date-picker="true"
              :show-dropdowns="true"
              :auto-apply="true"
              :ranges="false"
            ></date-range-picker>
            <!-- <input type="date" class="form-control" placeholder="Pick your date" v-model="addApt.date"> -->
            <div
              class="validate-error-message"
              v-if="$vd.addApt.ufDate.$hasError === true"
            >
              {{ $vd.addApt.$errors.ufDate[0] }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>From Time</label>
              <vue-timepicker
                autocomplete="on"
                v-model="addApt.from_time"
                format="h:mm A"
                :minute-interval="5"
                placeholder="hh:mm a"
              ></vue-timepicker>
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.addApt.time.$hasError === true"
            >
              {{ $vd.addApt.$errors.time[0] }}
            </div>

            <div class="form-group col-md-6">
              <label>To Time</label>
              <vue-timepicker
                autocomplete="on"
                v-model="addApt.to_time"
                format="h:mm A"
                :minute-interval="5"
                placeholder="hh:mm a"
              ></vue-timepicker>
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.addApt.time.$hasError === true"
            >
              {{ $vd.addApt.$errors.time[0] }}
            </div>
          </div>
          <!-- <div
            class="form-group"
            v-if="$storage.get('auth').user.user_type == 1"
          >
            <label for="inputAddress2">Assign to</label>
            <select
              class="form-control"
              v-model="addApt.assignTo"
              v-on:change="getApptsForAddNew($route.params.id)"
            >
              <option
                v-for="emp in employeesList"
                :key="emp.id"
                :value="emp.id"
              >
                {{ emp.name }}
              </option>
        
            </select>
          
          </div> -->
          <div class="form-group">
            <label for="inputAddress2"
              >Description
              <!--<span>(optional)</span>--></label
            >
            <textarea
              class="form-control"
              v-model="addApt.desc"
              rows="5"
            ></textarea>

            <div
              class="validate-error-message"
              v-if="$vd.addApt.desc.$hasError === true"
            >
              {{ $vd.addApt.$errors.desc[0] }}
            </div>
          </div>
        </div>

        <div class="modal-footer app-footer">
          <a
            v-if="this.$store.state.contacted == false"
            href="#"
            class="add-btn"
            v-on:click="toggleAddAppointment('hide')"
          >
            Back</a
          >

          <button :disabled="appointmentLoader" type="submit" class="rem-btn">
            <span v-if="appointmentLoader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!appointmentLoader"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Add</span
            >
          </button>
        </div>
      </form>
    </div>

    <!-- share Modal -->
    <div
      class="modal fade"
      id="exampleModal201"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Share</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="add-body share-modal">
              <form>
                <div class="form-group">
                  <label class="containerw">
                    Email
                    <input
                      type="radio"
                      value="Finance"
                      name="radio"
                      checked=""
                    />
                    <span class="checkmark"></span>
                  </label>
                  <input type="email" class="form-control" />
                </div>
                <div class="form-group">
                  <label class="containerw">
                    Text
                    <input type="radio" value="Finance" name="radio" />
                    <span class="checkmark"></span>
                  </label>
                  <input type="text" class="form-control" />
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <a href="#" class="add-btn"> Cancel</a>
            <a href="#" class="rem-btn"> Okay</a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal202"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Ros # {{ rodetail.ro_no }}
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="add-body share-modal">
              <!-- servicesinfo-box -->
              <div class="servicesinfo-box">
                <div class="row">
                  <div class="col-md-6">
                    <div class="pre-name">
                      <h2>
                        {{ rodetail.customer.first_name }}
                        {{ rodetail.customer.last_name }}
                      </h2>
                      <p>{{ rodetail.customer.email }}</p>
                      <p>{{ rodetail.customer.phone }}</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="pre-date">
                      <h5>
                        Date : {{ rodetail.created_at | deskingdateformate }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 col-lg-4">
                    <div class="phn-person">
                      <h4>
                        {{ rodetail.customer.v_year }}
                        {{ rodetail.customer.v_make }} <br />{{
                          rodetail.customer.v_model
                        }}
                      </h4>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="phn-person">
                      <p>
                        Licence Plate # :
                        <span>{{ rodetail.customer.licenceplate }}</span>
                      </p>
                      <p>VIN : <span>AW190376</span></p>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="phn-person">
                      <p>
                        Keytag : <span>{{ rodetail.customer.hangtag }}</span>
                      </p>
                      <p>
                        RO # : <span>{{ rodetail.ro_no }}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="sortable-items">
                  <form role="form" action="" method="POST">
                    <div class="multi-field-wrapper2 table-responsive-md">
                      <table class="table desksettig">
                        <thead>
                          <tr>
                            <th>Services</th>
                            <th></th>
                            <th>DMS Opcode</th>
                            <th class="text-right">Price $</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody class="multi-fields2 sortable ui-sortable">
                          <tr
                            class="
                              ui-state-default
                              multi-field2
                              ui-sortable-handle
                            "
                            v-for="data in rodetail.invoice"
                            :key="data.id"
                          >
                            <td>
                              <div class="names">
                                <span class="ddd"
                                  ><img
                                    class="img-fluid"
                                    src="assets/img/dots.svg"
                                    alt=""
                                /></span>
                                {{ data.name }}
                              </div>
                            </td>
                            <td>
                              <a href="#"
                                ><img
                                  class="img-fluid ddg"
                                  src="assets/img/edit-button.svg"
                                  alt=""
                              /></a>
                            </td>
                            <td>
                              <p>Rotate</p>
                            </td>
                            <td class="text-right">
                              <p>{{ data.price }}</p>
                            </td>
                            <td>
                              <img
                                class="remove-field2"
                                src="assets/img/colse.png"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <button type="button" class="add-field2">Add Item</button>
                      <div class="totals">
                        <ul>
                          <li>Total</li>
                          <li>{{ rodetail.total }}</li>
                        </ul>
                      </div>
                    </div>
                  </form>

                  <div class="col-md-9 offset-md-3">
                    <div class="e-signature">
                      <form>
                        <div class="form-group row">
                          <label
                            for="staticEmail"
                            class="col-sm-4 col-form-label"
                            >E-Signature :</label
                          >
                          <div class="col-sm-8">
                            <vueSignature
                              id="signatuteimg"
                              ref="signature"
                              :sigOption="option"
                              :w="'300px'"
                              :h="'100px'"
                              :defaultUrl="dataUrl"
                            ></vueSignature>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- servicesinfo-box -->

              <div class="share-print">
                <ul>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#exampleModal201"
                      ><img
                        class="img-fluid"
                        src="assets/img/share 1.svg"
                        alt=""
                      />
                      Share
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#exampleModal202"
                      ><img
                        class="img-fluid"
                        src="assets/img/printer.svg"
                        alt=""
                      />
                      Print</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- share Modal -->
    <!-- add-appointments -->
    <div
      class="modal fade"
      id="canvasImagemodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Result Image</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div
            class="container text-center"
            style="display: flex; align-items: flex-start"
          >
            <!-- <img
              class="img-fluid"
              :src="outputcar"
              alt=""
            /> -->

            <Editor
              :canvasWidth="canvasWidth"
              :canvasHeight="canvasHeight"
              ref="editor"
              editorId="canvasId"
            />
            <br /><br />
            <button
              @click="saveImage"
              :disabled="btnLoader"
              type="submit"
              class="rem-btn"
            >
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> save image</span>
            </button>
            <a class="btn" @click="undo">Undo</a>
            <a class="btn" @click="redo">Redo</a>
            <a class="btn" @click="backtoeditor">Back to editor</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Editor from "vue-image-markup";
import $ from "jquery";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueDaval from "vue-daval";
import vueSignature from "vue-signature";
import Progress from "easy-circular-progress";
export default {
  name: "services",
  components: {
    DateRangePicker,
    VueTimepicker,
    vueSignature,
    Progress,
    Editor,
  },
  mixins: [VueDaval],
  data() {
    return {
      imageUrl: require("../../assets/img/car3.png"),
      btnLoader: false,
      canvasimg: true,
      canvasimages: {},
      outputcar: "",
      canvasWidth: 700,
      canvasHeight: 300,
      image: [],
      carimage: [],
      signatuteimg: true,
      rodetail: {
        customer: {},
      },
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      dataUrl: "",
      type: "",
      employeesList: [],
      appointmentLoader: false,
      paymentLoader: false,
      addApt: {
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: "",
        desc: "",
        page: this.$route.name,
      },
      addTask: {
        automate: false,
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: "",
        name: "",
        type: "",
        page: this.$route.name,
        automation: "",
      },
      lTasks: [],
      Appointments: [],
      taskloader: false,
      yesterdayDate: moment().subtract(10, "y").format("YYYY-MM-DD"),
      stripe: {},
      cardElement: {},
      stripeAPIToken: "pk_test_YOUR_RANDOM_KEY",
      paymentProcessing: false,
      elements: "",
      card: "",
      name: "",
      makepayment: {},
      addPaymentStatus: 0,
      addPaymentStatusError: "",
      customer: {},
      roList: [],
      ro: [
        {
          service: "",
          price: "",
        },
      ],
      chart: false,
      donutseries: [49, 35],
      donutchartOptions: {
        chart: {
          type: "donut",
        },
        colors: ["#47CEFF", "#3151A1"],
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      series: [
        {
          name: "Net Profit",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "Revenue",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
        {
          name: "Free Cash Flow",
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        colors: ["#47CEFF", "#3151A1", "#19BE93", "#FFCB46", "#FC825C"],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 8,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "8:00 AM",
            "9:00 AM",
            "10:00 AM",
            "11:00 AM",
            "12:00 PM",
            "1:00 PM",
            "2:00 PM",
            "3:00 PM",
            "4:00 PM",
          ],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  vdRules: {
    addApt: {
      ufDate: { required: true },
      desc: { required: true },
      from_time: {
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "Invalid Time. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
      to_time: {
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "Invalid Time. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
      //tMin:{required: true}, tDtype: {required: true}
    },
  },
  async mounted() {
    this.stripe = await loadStripe("pk_test_6DGhi2hi4N2azLoBRcDzhO4V");
    const elements = this.stripe.elements();
    this.cardElement = elements.create("card", {
      classes: {
        base: "bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 p-3 leading-8 transition-colors duration-200 ease-in-out",
      },
    });
    this.cardElement.mount("#card-element");
    let customizeFreeDrawing = { stroke: "black", strokeWidth: "3" };

    this.$refs.editor.set("freeDrawing", customizeFreeDrawing);
  },
  created() {
    this.chart = true;
    this.getCustomerDetail();
    this.getRos();
    this.getCarImages();
    this.getCanvasImages();
  },

  computed: {
    calculateRo() {
      var sum = 0;
      this.ro.forEach((data) => {
        sum = Number(sum) + Number(data.price);
      });
      return sum;
    },
  },
  methods: {
    backtoeditor() {
      this.$refs.editor.set("freeDrawing");
    },
    getCanvasImages(id) {
      axios
        .get("canvasimages/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.canvasimages = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    undo() {
      this.$refs.editor.undo();
    },
    redo() {
      this.$refs.editor.redo();
    },
    openImageEditor(url, type) {
      this.type = type;
      console.log(url);
      //var img = require("../../assets/img/" + url);
      this.$refs.editor.setBackgroundImage(url);
      $("#canvasImagemodal").modal("show");
    },
    saveImage() {
      this.canvasimg = false;
      this.btnLoader = true;
      $("#canvasImagemodal").modal("show");
      this.outputcar = this.$refs.editor.saveImage();
      axios
        .post(
          "updateimagesketch",
          {
            output: this.outputcar,
            type: this.type,
            cus_id: this.customer.c_id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.btnLoader = false;
            this.getCanvasImages(this.customer.c_id);
            $("#canvasImagemodal").modal("hide");
            this.$toast.open({
              position: "top-right",
              message: "Image Updated",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoader = false;
          this.$toast.open({
            position: "top-right",
            message: "Something Wrong",
            type: "info",
          });
        });
    },
    getCarImages() {
      axios
        .get("carimages/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.carimage = res.data.carimages;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onImageChange(e) {
      this.files = e.target.files;
      this.imageUpload();
    },
    imageUpload() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + this.$storage.get("auth").token,
        },
      };

      let formData = new FormData();
      formData.append("cusid", this.$route.params.id);
      for (const i of Object.keys(this.files)) {
        formData.append("image[]", this.files[i]);
      }

      axios
        .post("/addcarimages", formData, config)
        .then((res) => {
          if (res.data.success) {
            this.files = "";
            this.getCarImages();
            this.$toast.open({
              position: "top-right",
              message: "Uploaded Successfully",
              type: "success",
            });
          }
        })

        .catch(function (error) {
          console.log(error);
        });
    },
    getRoDetail(ro) {
      axios
        .get("getRoDetail/" + ro, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.rodetail = res.data.ros;
          $("#exampleModal202").modal("show");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChangeHandeler(val) {
      this.type = val;
      if (val == "card") {
        $("#card").show();
        $("#check").hide();
        $("#cash").hide();
        $("#other").hide();
      }
      if (val == "check") {
        $("#card").hide();
        $("#check").show();
        $("#cash").hide();
        $("#other").hide();
      }
      if (val == "cash") {
        $("#card").hide();
        $("#check").hide();
        $("#cash").show();
        $("#other").hide();
      }
      if (val == "other") {
        $("#card").hide();
        $("#check").hide();
        $("#cash").hide();
        $("#other").show();
      }
    },
    toggleAddTask(action) {
      if (action == "show") {
        $(".add-task").addClass("show-add-tasks");
      } else if (action == "hide") {
        $(".add-task").removeClass("show-add-tasks");
      }
    },
    addNewAppointment() {
      this.appointmentLoader = true;
      //this.$route.params.id
      this.$vd.addApt
        .$validate()
        .then(() => {
          let startDate = this.addApt.ufDate.startDate;
          this.addApt.date = moment(new Date(startDate)).format("YYYY-MM-DD");
          this.addApt.from_time = moment(this.addApt.from_time).format(
            "h:mm A"
          );
          this.addApt.to_time = moment(this.addApt.to_time).format("h:mm A");
          //console.log(this.addApt);
          axios
            .post("service_ad_appointment", this.addApt, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                //contacted modal

                this.appointmentLoader = false;
                this.toggleAddAppointment("hide");
                this.addApt = {
                  date: "",
                  ufDate: { startDate: new Date(), endDate: new Date() },
                  time: "",
                  assignTo: this.$props.defaultAssignTo,
                  desc: "",
                  page: this.$route.name,
                };
                $(".vue__time-picker .clear-btn").trigger("click");
                $(".vue__time-picker .controls").trigger("click");
                this.getAppointments(this.$route.params.id);
                this.$root.$emit(
                  "action",
                  "update_touch_status",
                  res.data.lead_touch_resp_time
                );
                if (this.$route.name == "Dashboard") {
                  //this.getAppointments(0)
                } else {
                  //this.getAppointments(0)
                  //this.getAppointments(this.$route.params.id)
                  this.$root.$emit("action", "new_activity");
                }
                this.$root.$emit("action", "update_lead_status", 3);
                this.$root.$emit("action", "new_appointment");
                if (this.$store.state.contacted) {
                  console.log("appointment");

                  this.$store.commit("updateContacted", false);
                  return false;
                }
                if (res.data.lead_status < 3) {
                  this.$root.$emit("action", "update_lead_status", 3);
                }
              }
            })
            .catch((err) => {
              this.appointmentLoader = false;
              console.log(err);
            });
        })
        .catch(() => {
          console.log(this.$vd.addApt.$errors);
          this.appointmentLoader = false;
        });
    },
    toggleAddAppointment(action) {
      if (action == "show") {
        $(".add-appointment").addClass("show-appointments");
      } else if (action == "hide") {
        $(".add-appointment").removeClass("show-appointments");
      }
    },
    getAppointments() {
      axios
        .get("getinfoAppointment", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.Appointments = res.data.appointments;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async processPayment() {
      this.ro.forEach((data) => {
        if (data.price == "") {
          this.$toast.open({
            position: "top-right",
            message: "Please check service price.it seems empty ",
            type: "info",
          });
          this.paymentProcessing = false;
          return false;
        }
        if (data.service == "") {
          this.$toast.open({
            position: "top-right",
            message: "Please check service name.it seems empty ",
            type: "info",
          });
          this.paymentProcessing = false;
          return false;
        }
      });

      if (this.type == "card") {
        const { paymentMethod, error } = await this.stripe.createPaymentMethod(
          "card",
          this.cardElement,
          {
            billing_details: {
              name: this.customer.first_name + " " + this.customer.last_name,
              email: this.customer.email,
              address: {
                line1: this.customer.address,
                city: this.customer.city,
                state: this.customer.state,
                postal_code: this.customer.zip_code,
              },
            },
          }
        );
        if (error) {
          this.addPaymentStatus = false;
          this.paymentProcessing = true;
          console.error(error);
        } else {
          console.log(paymentMethod);
          this.makepayment.payment_method_id = paymentMethod.id;
          this.makepayment.amount = this.calculateRo * 100;
        }
        //  this.customer.cart = JSON.stringify(this.$store.state.cart);
      }
      axios
        .post(
          "makepayment",
          {
            payment: this.makepayment,
            ro: this.ro,
            id: this.$route.params.id,
            type: this.type,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            this.paymentProcessing = false;
            this.getRos();
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRos() {
      axios
        .get("getRo", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success == true) {
            this.roList = res.data.roList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createRo() {
      axios
        .post(
          "createdRo",
          {
            ro: this.ro,
            cus_id: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addService() {
      this.ro.push({
        service: "",
        price: "",
      });
    },
    deleteService(counter) {
      this.ro.splice(counter, 1);
    },
    getCustomerDetail() {
      axios
        .get("getCustomer/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success == true) {
            this.customer = res.data.customer;
            this.getCanvasImages(res.data.customer.c_id);
            console.log(res.data.customer);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 1067px;
}
.Leadsheets-table {
  padding: 16px;
}
.percent_more:after {
  position: absolute;
  left: 0.5em;
  top: 0em;
  right: 0;
  bottom: 0;
  background: #19cd9d;
  content: "";
}

.note-area {
  padding: 24px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 30px;
  /* box-shadow: 0 4px 8px rgb(49 81 161 / 8%); */
}
.add-appointment .vue-daterange-picker {
  width: 100% !important;
}
input.display-time {
  margin: 0;
  font-size: 14px !important;
  width: 100% !important;
  color: #6d778e;
  border: 1px solid #ccc !important;
  font-weight: 400;
  z-index: 2;
  background: transparent;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem !important;
  height: auto !important;
}
.vue__time-picker {
  width: 100% !important;
}
.vue__time-picker input.display-time:focus {
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}
.vue-daterange-picker {
  display: block !important;
}
.vue__time-picker input.display-time::-webkit-input-placeholder,
.vue__time-picker input.display-time::-ms-input-placeholder,
.vue__time-picker input.display-time::placeholder,
.vue__time-picker input.display-time:-ms-input-placeholder {
  color: #bbb;
}
</style>